const dispatch = {
  dispatch$changeEditMode: (payload) => ({
    type: 'CHANGE_EDITMODE',
    payload: payload
  }),
  dispatch$updatePanels: (payload) => ({
    type: 'UPDATE_PANELS',
    payload: payload
  }),
  dispatch$changePanelLayout: (payload) => ({
    type: 'CHANGE_PANELLAYOUT',
    payload: payload
  }),
  dispatch$changeAppConfig: (payload) => ({
    type: 'CHANGE_APPCONFIG',
    payload: payload
  })
};

export default dispatch;
