import React from 'react';

// Material UI
import {
  Card,
  CardContent,
  Typography
} from '@material-ui/core';

// Abstract
import withPortal from '../abstract/withPortal.jsx';

// Theme
import './Loading.scss';

const styles = () => ({
  loadingContent: {
    padding: '16px 56px'
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
});

class LoadingBox extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.loadingContent}>
        <CardContent className={classes.loadingWrapper}>
          <div className="loading-animation">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <Typography variant="h5" component="div">
            {this.props.msg}
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

export default withPortal(LoadingBox, {
  styles
});
