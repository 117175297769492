import React from 'react';
import ReactDOM from 'react-dom';
import FCPortal from './FCPortal.jsx';

function app(config) {
  ReactDOM.render(
    <FCPortal config={config} />,
    document.getElementById('app-portal')
  );
}

function init(config) {
  app(config);
}

export default init;
