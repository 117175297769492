// Theme
import { createMuiTheme } from '@material-ui/core/styles';

const themeStyles = {
  palette: {
    primary: {
      main: '#7bafdf',
      light: '#bcd7ef',
      dark: '#21588b',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#0C0C3A',
      light: '#4848d9',
      dark: '#06061d',
      contrastText: '#FFFFFF'
    },
    action: {
      disabled: '#888888'
    }
  }
};


const theme = createMuiTheme(themeStyles);

export default theme;
