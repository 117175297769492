import React from 'react';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withMediaQuery, breakpoints } from '../theme/withMediaQuery.js';

import mapStoreToProps from '../store/mapStoreToProps.js';
import mapDispatchToProps from '../store/mapDispatchToProps.js';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function withPortal(WrappedComponent, config) {
  const WithPortal = class extends React.Component {
    render() {
      return (
        <WrappedComponent {...this.props} />
      );
    }
  };
  WithPortal.displayName = `WithPortal(${getDisplayName(WrappedComponent)})`;

  return withStyles(config?.styles || (() => {}), {withTheme: true}) (
    withMediaQuery(breakpoints)(
      withSnackbar(
        connect(mapStoreToProps, mapDispatchToProps)(
          (
            WithPortal
          )
        )
      )
    )
  );
}

export default withPortal;
