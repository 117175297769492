const props = (state) => {
  return {
    store$isEditMode: state.isEditMode,
    store$panels: state.panels,
    store$panelLayout: state.panelLayout,
    store$appConfig: state.appConfig,
  };
};

export default props;
