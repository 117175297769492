const globalStyles = {
  _portalBg: {
    // background: 'linear-gradient(-15deg, #212164, #7bafdf)'
    background:
      'linear-gradient(to bottom, rgba(255,255,255,0.6), rgba(255,255,255,0.6)),'+
      'linear-gradient(-45deg, rgba(94,133,7,1), rgba(67,95,5,0.25) 70%, rgba(60,85,4,0.01)),'+
      'linear-gradient(-135deg, rgba(133,181,220,1), rgba(120,173,217,0.25) 70%, rgba(105,164,212,0.01)),'+
      'linear-gradient(-255deg, rgba(0,91,144,1), rgba(0,66,104,0.25) 70%, rgba(0,59,93,0.01)),'+
      'linear-gradient(45deg, rgba(0,137,148,1), rgba(0,100,108,0.25) 70%, rgba(0,90,97,0.01))'
  }
};

export default globalStyles;
