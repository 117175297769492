import { bindActionCreators } from 'redux';

import dispatchAuth from './auth/mapDispatchToProps.js';
import dispatchDashboard from './dashboard/mapDispatchToProps.js';
import dispatchGuis from './guis/mapDispatchToProps.js';
import dispatchPortal from './portal/mapDispatchToProps.js';

const dispatchFunctions = {
  ...dispatchAuth,
  ...dispatchDashboard,
  ...dispatchGuis,
  ...dispatchPortal,
};

export default (dispatch) => {
  return bindActionCreators(dispatchFunctions, dispatch)
};
